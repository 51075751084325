import { DICT } from './dict';


function getToken() {
  let tokenData = '';

  try {
    tokenData = $.cookie(DICT.TOKEN_KEY);
  }
  catch (err) {
    tokenData = '';
  }

  return tokenData;
}

const cookieOption = {
  // expires: 7,
  path: '/',
};

if (process.env.ROOT_DOMAIN && -1 < document.domain.indexOf(process.env.ROOT_DOMAIN)) {
  cookieOption.domain = process.env.ROOT_DOMAIN;
}

// $.ajaxPrefilter((options, originalOptions, jqXHR) => {
$.ajaxPrefilter((options) => {
  // window.console.log('options', options, 'originalOptions', originalOptions, 'jqXHR', jqXHR);

  const headers = {
    'Content-Type': 'application/json; charset=UTF-8',
    ...options.headers,
  };

  const tokenData = getToken();

  if (tokenData && !headers.Authorization) {
    headers.Authorization = `Bearer ${tokenData}`;
  }

  // headers.time = 'time';
  // headers.sign = 'sign';
  // headers['nonce-str'] = 'nonce-str';

  const success = options.success;
  const error = options.error;
  const successWarp = (data, textStatus, cbJqXHR) => {
    // window.console.log('successWarp', data, textStatus, cbJqXHR);
    if (429 === _.get(data, 'code') || 429 === textStatus) {
      window.location.href = '/website/system_error';
      return;
    }

    if (401 === _.get(data, 'code')) {
      jQuery(window).trigger(DICT.EVENT.CAS_AUTH_401, data);
    }

    if ('function' === typeof success) {
      return success(data, textStatus, cbJqXHR);
    }
  };


  const errorWarp = (cbJqXHR, textStatus, errorThrown) => {
    // window.console.log('errorWarp', cbJqXHR, textStatus, errorThrown);
    if ('function' === typeof error) {
      return error(cbJqXHR, textStatus, errorThrown);
    }
  };

  Object.assign(options, {
    success: successWarp,
    error: errorWarp,
    headers: {
      ...headers,
    },
  });
});

export {
  cookieOption,
  getToken,
};
