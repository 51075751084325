import { DICT } from './dict';

const announcementState = {
  effctsLoading: false,
  detail: {},
  list: [],
};

let ModalInfo = null;


function ModalContent() {
  const styles = `.ant-modal-confirm-info .ant-modal-confirm-body>.anticon { display: none }
  .announcement .ant-modal-confirm .ant-modal-confirm-btns { display: none }
  .announcement-content {
    margin-top: 10px;
  }
  .ant-modal-confirm .ant-modal-close, .ant-modal-confirm .ant-modal-header { display: block }
  .ant-modal-confirm-body .ant-modal-confirm-content { margin: 0 }
  .ant-modal-confirm .ant-modal-confirm-btns { 
    float: none; display: flex;
    align-items: center;
    justify-content: center; 
  }
  .announcement-content .content {
    margin-top: 20px;
    min-height: 300px;
    max-height: 600px;
    overflow: auto;
  }
  .announcement-cell {
    margin-top: 10px;
  }`;

  return (<div className="announcement-content">
    <style>{styles}</style>
    <div className="announcement-cell">
        发布日期： {_.get(announcementState, 'detail.created_at')}
    </div>
    <div className="braft-output-content content" {...{ dangerouslySetInnerHTML: { __html: _.get(announcementState, 'detail.system_announcement_content.content') } }} />
  </div>);
}


function getNextAnnouncement(prevId) {
  const prevIndex = _.findIndex(announcementState.list, { id: prevId });
  const nextId = _.get(announcementState, `list.${prevIndex + 1}.id`);
  ModalInfo.destroy();
  if (nextId) {
    getDetail(nextId);
  }
}

function getDetail(id) {
  announcementState.effctsLoading = true;
  const select = 'id,type,title,editor_id,created_at,updated_at,pop_num,systemAnnouncementContent.content';

  $.ajax({
    url: `${DICT.API_DOMAIN_WITH_PREFIX}/system_announcement/${id}`,
    type: 'get',
    data: {
      orderBy: 'id',
      sort: 'desc',
      select,
    },
    success: (res) => {
      announcementState.effctsLoading = false;
      announcementState.detail = _.get(res, 'data');

      ModalInfo = antd.Modal.info({
        icon: null,
        mask: true,
        width: 800,
        okText: _.get(announcementState.detail, 'id') !== _.get(_.last(announcementState.list), 'id') ? '查看下一条' : '关闭',
        onOk: getNextAnnouncement.bind(null, _.get(announcementState.detail, 'id')),
        maskClosable: true,
        className: 'announcement',
        title: _.get(announcementState, 'detail.title'),
        content: <ModalContent />,
      });
      return res;
    },
  });
}

function getDefaultFilter(list) {
  if (_.isEmpty(list)) {
    return [];
  }
  const sortList = _.sortBy(list, 'id');
  const first = _.first(sortList);
  if (_.get(first, 'popedAll')) {
    return [['id', '>', first.id]];
  }
  return [['id', '>=', _.get(first, 'id')]];
}

function getUnreadList(list) {
  if (_.isEmpty(list)) {
    return [];
  }
  const sortList = _.sortBy(list, 'id');
  const len = sortList.length;
  const readIndex = _.findLastIndex(sortList, { popedAll: true });
  if (-1 === readIndex) {
    return sortList;
  }
  if (len - 1 === readIndex) {
    return [_.last(sortList)];
  }
  return sortList.slice(readIndex + 1);
}

export default function showAnnouncement(User) {
  const storageKey = `important_${User.id}_READ_LIST`;
  const LAST_READ_LIST = localStorage.getItem(storageKey) ? JSON.parse(localStorage.getItem(storageKey)) : [];
  const filter = getDefaultFilter(LAST_READ_LIST);

  filter.push([
    'system_announcement_types.user_type',
    '=',
    _.get(User, 'user_type'),
  ]);

  $.ajax({
    url: `${DICT.API_DOMAIN_WITH_PREFIX}/system_announcement`,
    type: 'get',
    data: {
      orderBy: 'id',
      sort: 'desc',
      select: 'id,pop_num',
      filter: JSON.stringify(filter),
    },
    success: (res) => {
      announcementState.list = _.get(res, 'data.data');
      _.each(_.get(res, 'data.data'), (item) => {
        const target = _.find(LAST_READ_LIST, { id: item.id });
        if (target) {
          target.popedNum += 1;
          if (_.get(target, 'popedNum') * 1 >= _.get(item, 'pop_num')) {
            target.popedAll = true;
          }
          return true;
        }
        LAST_READ_LIST.push({
          id: _.get(item, 'id'),
          popedNum: 1,
          popedAll: 1 === _.get(item, 'pop_num'),
        });
      });
      const announcementIds = _.map(announcementState.list, 'id');
      const headId = _.head(announcementIds);
      if (!headId) {
        return;
      }


      const filterReadList = getUnreadList(LAST_READ_LIST);

      localStorage.setItem(storageKey, JSON.stringify(filterReadList));
      getDetail(headId);
    },
  });
}
